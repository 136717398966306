import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { UserAuthService } from '../services/user-auth.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

  lang: string;

  constructor(
    public userAuth: UserAuthService
  ) {
    this.userAuth.currentMessage.subscribe(message => this.lang = message)

   }

  ngOnInit(): void {
    this.lang = localStorage.getItem('lang')
  }

}
