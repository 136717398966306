<div class="pink text-center">
    <span class="error" *ngIf="this.status !== 'err'">{{this.data | json}}</span>
    <img class="logo" src="../../../assets/images/moyee_logo_white@2x.png" />
    <img class="img-handbeans" src="../../../assets/images/main-page-hand.png" />
    <p class="text" style="margin-top: 35px;"> {{'succes-title-1' | translate}}</p>
    <p class="text" style="padding-bottom: 65px;">{{'succes-title-2' | translate}}</p>
</div>

<div class="container">
    <p class="text1">{{'succes-sub-title' | translate}}<img class="bag" src="../../../assets/images/coffee_package_1@2x.png"></p>
    <p class="text1">{{'succes-info' | translate}}</p>
    <span class="pinktext2">{{'succes-footer-title' | translate}}</span>
    <p class="pinktext">{{'succes-footer-site' | translate}}<br /> {{'succes-footer-phone' | translate}}<br /> {{'succes-footer-email' | translate}}</p>
    <p class="socials text-center">
        <a target="_blanc" href="https://www.youtube.com/channel/UC6e_Tjx81A8AqtWh_scccQA">
            <img src="../../../assets/images/youtube.png" />
        </a>

        <a target="_blanc" href="https://www.instagram.com/moyeecoffee/">
            <img class="social" src="../../../assets/images/instagram.png" />
        </a>

        <a target="_blanc" href="https://www.facebook.com/moyeecoffee/">
            <img class="social" src="../../../assets/images/facebook.png" />
        </a>

        <a target="_blanc" href="https://www.linkedin.com/company/moyeecoffee/">
            <img class="social" src="../../../assets/images/linkedin.png" /></a>
    </p>
</div>
