import { Injectable, NgZone } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { User } from "./user";
import { AngularFireAuth } from "@angular/fire/auth";
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class UserAuthService {
  public ikDoeMeeBtn:boolean = true;
  public userData: any; // Save logged in user data


  private messageSource = new BehaviorSubject(null);
  currentMessage = this.messageSource.asObservable();
  devUrl = "http://127.0.0.1:5001/earthday-01/us-central1/app"
  prodUrl = "https://us-central1-earthday-01.cloudfunctions.net/app"

  constructor(
    public http: HttpClient,
    public router: Router,
    public afs: AngularFirestore,   // Inject Firestore service
    public afAuth: AngularFireAuth, // Inject Firebase auth service
    public ngZone: NgZone, // NgZone service to remove outside scope warning
    public translate: TranslateService

  ) { }

  switchLang(lang: string) {
    this.translate.use(lang);
  }

  changeMessage(message: string) {
    this.messageSource.next(message)
  }


  // Sign in with email/password
  SignIn(email: string, password: string) {
    return this.afAuth.auth.signInWithEmailAndPassword(email, password)
      .then((result) => {
        this.ngZone.run(() => {
          this.router.navigate(['']);
          return 'succes'
        });
        this.SetUserData(result.user);
      }).catch((error) => {
        return error.message
      })
  }

  SetUserData(user: any) {
    const userRef: AngularFirestoreDocument<any> = this.afs.doc(`users/${user.uid}`);
    const userData: User = {
      uid: user.uid,
      email: user.email,
    }
    return userRef.set(userData, {
      merge: true
    })
  }

  create(email) {
    let body = {
      "email": `${email.email}`,
      "lang": localStorage.getItem('lang')
    };
    //console.log(email.email)

    return this.http.post(`${this.prodUrl}/Earthday/sendmail`, body)
  }

  plantTree(email, email_2, tokenId) {
    let body = {
      "email": `${email}`,
      "email2": `${email_2}`,
      "tokenid": `${tokenId}`,
      "lang": localStorage.getItem('lang')
    }
    return this.http.post(`${this.prodUrl}/Earthday/planttree`, body)
  }

  getUserData(email) {
    let body = {
      "email": `${email}`
    };
     return this.http.post(`${this.prodUrl}/Earthday/userinfo`, body);
  }


}
