<div class="wrapper">
    <div *ngIf="!this.emailSent">
        <img class="img-handbeans" src="../assets/images/main-page-hand.png" /><br />
        <span class="text">
          <br> {{'req-title-2' | translate}}  <br /> {{'req-title-3' | translate}}
        </span>
        <br>
        <span class="text1" style="font-size: 125%">
          {{'req-head-1' | translate}}<br>
          {{'req-head-2' | translate}}<br>
          {{'req-head-3' | translate}}<br>
        </span>
        <form #userForm="ngForm" (ngSubmit)="onSubmit(userForm.value)">
            <input class="form-control ph transparent-input" type="text" #email="ngModel" email required name="email"
                placeholder="{{'req-input-placeholder' | translate}}" ngModel />

            <span *ngIf="email.invalid && email.touched" class="error"><b>{{'req-input-error' | translate}}</b></span>

            <button [disabled]="userForm.invalid || loader" type="submit" class="btn">
                <div *ngIf="!loader">{{'req-input-button' | translate}}</div>
                <div class="loader" *ngIf="loader">
                    <div class="fa-1x">
                        <i class="fas fa-sync fa-spin" style="color: white;"></i>
                    </div>
                </div>
            </button>
        </form>

        <div class="text1">
            <span class="privacy-policy">{{'req-description' | translate}}<br>
                <a target="_blank" href="https://www.moyeecoffee.com/privacy-policy/"  class="privstat">{{'req-privacy-statement' | translate}}</a></span>
        </div>
    </div>
    <div *ngIf="this.emailSent" style="padding-top:9rem; height: 711px;">
        <div *ngIf="returnMsg == 'all tokens have been used'">
            <span class="text">
               {{'req-tokens-used-error' | translate}}
            </span>
        </div>
        <div *ngIf="returnMsg == 'de mail is verstuurd'">
            <span class="text">
                {{'req-succes-1' | translate}} <br> {{'req-succes-2' | translate}} <br>  {{'req-succes-3' | translate}}
            </span>
        </div>
        <div *ngIf="returnMsg == 'already redeemed 5 tokens'">
            <span class="text">
               {{'req-tokens-redeemd-error-1' | translate}} <br> {{'req-tokens-redeemd-error-2' | translate}}
            </span>
        </div>
        <!-- <div *ngIf="returnMsg == 'email already used'">
            <br>
            <span class="text">Oops! met dit emailadres is al een keer een boom geplant.</span>
            <p class="paragraph"> Klik op de knop als je op een andere manier nog een boom wilt planten.</p>
            <button class="btn">PLANT NOG EEN BOOM</button>
        </div> -->
        <div *ngIf="returnMsg == 'email badly formatted'">
            <span class="text">
                <br>{{'req-email-formatted-error-1'| translate}} <br> {{'req-email-formatted-error-2'| translate}}
            </span><br>
            <button class="btn" (click)="resetPage()">{{'req-email-formatted-button-error' | translate}}</button>
        </div>

        <div *ngIf="returnMsg == 'de mail is verstuurd'">
            <p class="paragraph">
               {{'req-succes-info-1' | translate}} <b>{{emailadress}}</b> {{'req-succes-info-2' | translate}}
            </p>
        </div>
    </div>
    <div>
        <img class="divider" src="../../../assets/images/bow.png" />
    </div>
</div>
