<div class="container">
    <br />
    <span class="textpink">{{'extra-section-1-title' | translate}}</span>
    <p class="text1">{{'extra-section-1-description' | translate}}
    </p>

    <div class="info">
        <div class="infotext">
            <span id="planted" class="textpink2">{{'extra-section-1-data-1' | translate}}</span>
            <label class="label_planted" for="planted">{{'extra-section-1-data-1-title' | translate}}</label>
        </div>
        <div class="infotext">
            <span class="textpink2">{{'extra-section-1-data-2' | translate}}</span>
            <span class="undertext">{{'extra-section-1-data-2-title' | translate}}</span>
        </div>
        <div class="infotext2">
            <span class="textpink2">{{'extra-section-1-data-3' | translate}}</span>
            <span class="undertext">{{'extra-section-1-data-3-title' | translate}}</span>
        </div>
    </div>


    <br />
    <span class="textgray">{{'extra-section-1-sub-title' | translate}}</span>
    <p class="text1">{{'extra-section-1-sub-info-1' | translate}} <br>{{'extra-section-1-sub-info-2' | translate}}</p>
    <p class="text">{{'extra-section-1-sub-description' | translate}}
    </p>
</div>
<div class="brown">
    <img class="tree" src="{{'extra-section-2-img-tree' | translate}}" />
    <img class="numbers" src="{{'extra-section-2-img-data' | translate}}" />
    <p class="text1">{{'extra-section-2-text-1' | translate}}</p>
    <p class="text">{{'extra-section-2-text-2' | translate}}<br> {{'extra-section-2-text-2-hashtag' | translate}}</p>
    <br>
    <br>
    <span class="textpink">{{'extra-section-2-title'| translate}}</span>
    <p class="text1">{{'extra-section-2-description'| translate}}
    </p>
    <p class="text">{{'extra-section-2-description-2'| translate}}</p>
    <p class="text">{{'extra-section-2-description-3'| translate}}</p>
    <img class="updatesimg" src="{{'extra-section-2-img' | translate}}" />
    <div class="process">
        <img class="onderweg" src="{{'extra-section-2-img-update' | translate}}" />
        <img class="onderweg2" src="{{'extra-section-2-img-update-mobile' | translate}}" />
    </div>
</div>
<div *ngIf="this.userS.ikDoeMeeBtn">
    <button type="submit" class="btn" (click)="scrollToTop()">{{'req-input-button' | translate}}</button>
    <p class="text1">{{'req-description' | translate}}<br /><a target="_blank"
            href="https://www.moyeecoffee.com/privacy-policy/" class="privstat">{{'req-privacy-statement' | translate}}</a></p>
</div>
<div class="accordion">
    <div>
        <input type="checkbox" name="faq" id="section1" class="accordion-input">
        <label for="section1" class="accordion-label">{{'extra-faq-1-title' | translate}}</label>
        <div class="accordion-content">
            <p style="font-size: 14px;">{{'extra-faq-1-description' | translate}}</p>
        </div>
    </div>
    <div>
        <input type="checkbox" name="faq" id="section2" class="accordion-input">
        <label for="section2" class="accordion-label">{{'extra-faq-2-title' | translate}}</label>
        <div class="accordion-content">
            <p style="font-size: 14px;">
              {{'extra-faq-2-description' | translate}}
            </p>
        </div>
    </div>
    <div>
        <input type="checkbox" name="faq" id="section3" class="accordion-input">
        <label for="section3" class="accordion-label">{{'extra-faq-3-title' | translate}}</label>
        <div class="accordion-content">
            <p style="font-size: 14px;">
              {{'extra-faq-3-description' | translate}}
            </p>
        </div>
    </div>
    <div>
        <input type="checkbox" name="faq" id="section4" class="accordion-input">
        <label for="section4" class="accordion-label">{{'extra-faq-4-title' | translate}}</label>
        <div class="accordion-content">
            <p style="font-size: 14px;">
              {{'extra-faq-4-description-1' | translate}}<br><br> {{'extra-faq-4-description-2' | translate}}
            </p>
        </div>
    </div>

</div>
