<footer class="en" *ngIf="lang == 'en'">
    <div class="container en">
        <div class="row row-cols-1 row-cols-sm-2 row-cols-md-5">

            <div class="col logo">
                <img class="footer-logo" src="../../assets/images/moyee_m_logo3.png" alt="">
            </div>

            <div class="col">
                <h4 class="contact-title">CONTACT</h4>
                <ul>
                    <li class="contact-item">Moyee Coffee Ireland</li>
                    <li class="contact-item">Spade Enterprise Centre,</li>
                    <li class="contact-item">North King St,</li>
                    <li class="contact-item">Dublin 7,</li>
                    <li class="contact-item">D07yt52,</li>
                    <li class="contact-item">Ireland</li>
                </ul>

                <p class="contact-tel">{{'footer-tel' | translate}}</p>
                <p class="contact-email">{{'footer-email' | translate}}</p>
            </div>

            <div class="col shop">
                <h4 class="shop-title">SHOP</h4>

                <ul class="shop-list">
                    <li class="shop-item"><a class="shop-link" href="https://moyeecoffee.ie/pages/impactcoffeeclub">The Impact Coffee Club</a></li>
                    <li class="shop-item"><a class="shop-link" href="https://moyeecoffee.ie/collections/our-coffee">Our Coffee</a></li>
                    <li class="shop-item"><a class="shop-link" href="https://moyeecoffee.ie/collections/gifts">Gifts</a> </li>
                    <li class="shop-item"><a class="shop-link" href="https://moyeecoffee.ie/collections/brew-gear">Brew Gear</a> </li>
                    <li class="shop-item"><a class="shop-link" href="https://moyeecoffee.ie/collections/coffee-machines">Coffee Machines</a> </li>
                    <li class="shop-item"><a class="shop-link" href="https://moyeecoffee.ie/account/login">Customer Login</a> </li>
                </ul>
            </div>

            <div class="col learn">
                <h4 class="learn-title">LEARN</h4>

                <ul>
                    <li class="learn-item"><a class="learn-link" href="https://moyeecoffee.ie/pages/about-moyee">About Us</a> </li>
                    <li class="learn-item"><a class="learn-link" href="https://moyeecoffee.ie/pages/returns">Returns Policy</a> </li>
                    <li class="learn-item"><a class="learn-link" href="https://moyeecoffee.ie/pages/shipping">Shipping Policy</a> </li>
                    <li class="learn-item"><a class="learn-link" href="https://moyeecoffee.ie/pages/privacy-policy">Privacy Policy</a> </li>
                    <li class="learn-item"><a class="learn-link" href="https://moyeecoffee.ie/pages/terms-of-service">Terms of Services</a> </li>
                </ul>
            </div>

            <div class="col join-us">
                <h4 class="join-us-title">JOIN US</h4>

                <p class="join-us-info">Sign up to our newsletter and learn how your love of coffee can have a big impact</p>

                <input class="join-us-input col-md-12" type="text" placeholder="Enter your email adress...">
                <button class="join-us-button">SIGN UP</button>

                <ul>
                    <li class="join-us-item"><a class="join-us-icon" target="_blank" href="https://twitter.com/moyeecoffee"><i
                class="fab fa-twitter"></i></a></li>
                    <li class="join-us-item"><a class="join-us-icon" target="_blank" href="https://www.facebook.com/MoyeeCoffeeIreland/"><i class="fab fa-facebook-f"></i></a></li>
                    <li class="join-us-item"> <a class="join-us-icon" target="_blank" href="https://www.instagram.com/moyeecoffeeirl/"><i class="fab fa-instagram"></i></a></li>
                    <li class="join-us-item"><a class="join-us-icon" target="_blank" href="https://www.linkedin.com/company/moyee-coffee-ireland"><i class="fab fa-linkedin-in"></i></a></li>
                    <li class="join-us-item"><a href="mailto:hello@moyeecoffee.ie" class="join-us-icon"><i class="far fa-envelope"></i></a></li>
                </ul>
            </div>

        </div>
        <div class="col-12 copyright">
            <p class="copyright-title">&copy; 2021 Moyee Coffee.</p>
        </div>
    </div>
</footer>


<footer class="nl" *ngIf="lang == 'nl'">
    <div class="container">
        <div class="row row-cols-1 row-cols-sm-2 row-cols-md-4">
            <div class="col logo">
                <img class="footer-logo" src="../../assets/images/moyee_m_logo3.png" alt="">
                <p class="copyright-title hidden-phone">&copy; Moyee Coffee 2020</p>
            </div>
            <div class="col">
                <h4 class="contact-title title">CONTACT</h4>
                <div class="items">
                    <p class="contact-info">Wil je graag even wat kwijt, of <br> heb je een vraag? Let us know!</p>
                    <p class="contact-info">Bel: 020-7372295 <br>
                        <p class="contact-info">Rhoneweg 44 <br>1043 AH Amsterdam</p>
                        <p class="contact-info">> Moyee England & Ireland</p>
                </div>
            </div>
            <div class="col">
                <h4 class="menu-title">MENU</h4>
                <div class="items">
                    <p><a class="menu-item" href="https://fairchain.org/">FAIRCHAIN FOUNDATION</a></p>
                    <p><a class="menu-item" href="https://moyeecoffee.freshdesk.com/support/home">FAQ</a></p>
                    <p><a class="menu-item" href="https://www.moyeecoffee.com/join-moyee/">JOIN MOYEE</a></p>
                    <p><a class="menu-item" href="https://www.moyeecoffee.com/algemene-voorwaarden/">ALGEMENE VOORWAARDEN</a></p>
                    <p><a class="menu-item" href="https://www.moyeecoffee.com/privacy-policy/">PRIVACY POLICY</a></p>
                    <p><a class="menu-item" href="https://www.moyeecoffee.com/governance/">GOVERNANCE</a></p>
                    <p><a class="menu-item" href="https://www.moyeecoffee.com/contact/">CONTACT</a></p>
                </div>
            </div>
            <div class="col">
                <h4 class="keep-in-touch-title">KEEP IN TOUCH</h4>
                <div class="items item-icon">

                    <p class="icon"><a class="keep-in-touch-link" href="https://www.facebook.com/moyeecoffee/"><i class="fab fa-facebook-f"></i></a></p>

                    <p class="icon"><a target="_blank" href="https://www.instagram.com/moyeecoffee/"><i class="fab fa-instagram"></i></a></p>
                    <p class="icon"><a target="_blank" href="https://twitter.com/moyeecoffee"><i class="fab fa-twitter"></i></a></p>
                    <p class="icon"><a target="_blank" href="https://www.linkedin.com/company/moyeecoffee/"><i class="fab fa-linkedin"></i></a></p>
                    <p class="icon"><a target="_blank" href="https://www.youtube.com/channel/UC6e_Tjx81A8AqtWh_scccQA"><i class="fab fa-youtube"></i></a></p>


                </div>
            </div>
            <div class="copyright">
                <p class="footer-copyright view-phone"> &copy; Moyee Coffee 2020</p>
            </div>
        </div>
    </div>
</footer>
