import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { UserAuthService } from 'src/app/services/user-auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'Earthday';
  userLang: string;
  langs: any;
  constructor(
    public translate: TranslateService,
    public userAuth: UserAuthService
  ) {
    translate.addLangs(['en', 'nl']);
    translate.setDefaultLang('en');
    // this.userLang = navigator.language
    // this.langs = this.userLang.split('-')
    console.log(this.userLang);
    // this.userAuth.currentMessage.subscribe(message => this.userLang = message)

    // if(this.userLang == null){

      // if (!localStorage.getItem('lang')) {
      //   localStorage.setItem('lang', this.langs[0])
      //   this.userLang = this.langs[0]

      // } else {
      //   this.userLang = localStorage.getItem('lang')
      // }

    // }



    // this.switchLang(this.userLang);
  }

  ngOnInit() {


  }

  switchLang(lang: string) {
    this.translate.use(lang);
  }

}
