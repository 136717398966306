import { Component, OnInit } from '@angular/core';
import { UserAuthService } from 'src/app/services/user-auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'app-email-success',
  templateUrl: './email-success.component.html',
  styleUrls: ['./email-success.component.css']
})
export class EmailSuccessComponent implements OnInit {
  email: any;
  email_2: any;
  tokenId: any;
  constructor(public userS: UserAuthService, public route: ActivatedRoute, public translate: TranslateService) {
    this.lang = this.route.snapshot.params['lang'];
    localStorage.setItem('lang', this.lang)
    this.userS.switchLang(this.lang);
   }

  msg:string;
  status:string;
  data:any;
  lang: any;


  ngOnInit(): void {
    this.email = this.route.snapshot.params['email_1'];
    this.email_2 = this.route.snapshot.params['email_2'];
    this.tokenId = this.route.snapshot.params['tokenId'];


    // console.log(this.email, this.email_2, this.tokenId)
    this.userS.plantTree(this.email, this.email_2, this.tokenId).subscribe((res:any)=> {
      console.log(res);

      if(res.status === 'err'){

        this.msg = res.msg;
        this.status = status;
        this.data = res
      }
    });

  }

  switchLang(lang: string) {
    this.translate.use(lang);
  }

}
