<app-header></app-header>

<div class="container">
<div class="login">
  <div class="wrap">
      <div class="logo"><img class="moyee" src="../../../assets/images//moyee_m_logo2.png"/></div>
    <form method="post">
      <p class="message">{{this.message}}</p>
      <input #email type="email" id="email" placeholder="Email">
      <input #password type="password" id="password" placeholder="Password">
      <input (click)="this.signIn(email.value, password.value)" type="submit" class="submit" id="login" value="Login">
    </form>
  </div>
</div>
</div>
