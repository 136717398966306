import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { EmailRegComponent } from './components/email-reg/email-reg.component';
import { EmailSuccessComponent } from './components/email-success/email-success.component';
import { HomeComponent } from './components/home/home.component';
import { LoginComponent } from './components/login/login.component';
import { UpdatesComponent } from './components/updates/updates.component';


const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'success/:email_1/:email_2/:tokenId/:lang', component: EmailSuccessComponent },
  { path: 'login', component: LoginComponent },
  { path: 'updates/:email_1/:lang', component: UpdatesComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
