<nav class="col-12 navbar-expand-lg navbar navbar-dark bg-dark darkbackground">
  <a class="navbar-brand" routerLink=""><img class="header-logo" src="../assets/images/moyee_m_logo4.png" width="30"
      height="30" alt=""></a>
  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav"
    aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>
  <div class="collapse navbar-collapse" id="navbarNav">
    <ul class="navbar-nav">
      <li class="nav-item">
        <a class="nav-link" href="{{'header-link-shop' | translate}}">SHOP</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" href="{{'header-link-learn' | translate}}">LEARN</a>
      </li>
      <li>
        <div class="dropdown ">
          <div id="dropdownMenuButton" data-toggle="dropdown" class="trigger">

          <a ><img class=" language-switcher"
              src="{{'header-language-switcher-flag-active' | translate}}" alt=""> </a>
          <i class="dropdown-icon fas fa-angle-down"></i>
        </div>

          <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
            <a class="dropdown-item" (click)="switchLanguage(countryCode.value)" class="dropdown-item"><img src="{{'header-language-switcher-flag-not-active' | translate}}" ></a>
            <input type="hidden" value="{{'header-language-switcher-flag-not-active-code' | translate}}" #countryCode>
          </div>
        </div>
      </li>
    </ul>
  </div>
</nav>
