import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserAuthService } from 'src/app/services/user-auth.service';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';



@Component({
  selector: 'app-email-reg',
  templateUrl: './email-reg.component.html',
  styleUrls: ['./email-reg.component.css']
})
export class EmailRegComponent implements OnInit {
  faCircleNotch = faCircleNotch;

  emailSent = false;
  emailadress;
  errorMessage: string;
  returnMsg;
  loader: boolean = false;

  constructor(public userS: UserAuthService, public router: Router,) { }


  ngOnInit() {
  }

  onSubmit(email) {

    this.emailadress = JSON.stringify(email.email);
    //console.log(email.email);
    this.loader = true;

    this.userS.create(email).subscribe((res: any) => {
      this.emailSent = true;
      this.userS.ikDoeMeeBtn = false;
      console.log(res);
      this.loader = false;
      if (res.status === 'err') {
        console.log(res.msg)
        this.returnMsg = res.msg
      }
      this.returnMsg = res.msg
      console.log(res.msg)
    });
  }

  resetPage() {
    this.emailadress = "";
    this.emailSent = false;
    this.loader = false;
  }
}
