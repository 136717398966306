<app-header></app-header>
<div class="wrapper text-center">

    <div class="result">
        <div class="results">
            <span class="error" *ngIf="this.status === 'err'">{{this.data | json}}</span>
            <p class="text" style="padding-top: 5.96vh;">{{'update-title-1' | translate}}<br> {{'update-title-2' | translate}}<br> {{'update-title-3' | translate}}</p>
            <p class="text1"><img class="check" src="../../../assets/images/icon_check.png" />{{'update-sub-title' | translate}}</p>
        </div>
        <div class="results2">
            <span class="float-left d-block text2">{{'update-info-1' | translate}}</span>
            <p *ngIf="numb_planted" class="float-left d-block text">{{numb_planted}}</p>
            <span class="float-left d-block text2">{{'update-info-2' | translate}}</span>
        </div>
        <div>
        </div>
        <div class="info">
            <div class="infotext" style="height: 116px">
                <span *ngIf="numb_planted" class="text3">-{{kilogram()}} {{'update-data-1' | translate}} </span>
                <span class="undertext">{{'update-data-1-title-1' | translate}}</span><span class="undertext2">{{'update-data-1-title-2' | translate}}</span>
            </div>
            <div class="infotext2">
                <span *ngIf="numb_planted" class="text3">+{{euro()}} {{'update-data-2' | translate}}</span>
                <span class="undertext">{{'update-data-2-title-1' | translate}}</span><span class="undertext2">{{'update-data-2-title-2' | translate}}</span>
            </div>
        </div>
        <img class="updatesimg" src="{{'extra-section-2-img' | translate}}" />
    </div>
    <img class="divider" src="../../../assets/images/bow.png" />
</div>
<div class="container">
    <div *ngIf="this.data">
        <div class="cards" *ngFor="let i of data| keyvalue; let j = index">
            <div class="card" style="width: 18rem;">
                <div class="card-body"
                    style="padding-right: 10px;padding-left: 10px; padding-top: 10px; padding-bottom: 0px;">
                    <img class="card-img-top" src="../../../assets/images/photo_1@2x.png" alt="Card image cap">
                    <div class="treenumberbox">
                        <p class="treenumber" [innerHTML]="'#' + (1 + j)"></p>
                    </div>
                    <h5 class="textpink3">{{'update-card-title'| translate}}</h5>
                    <h5 class="text6">{{i.value.usedAt | json | date:'EEEE, dd, MMMM, HH:mm, zzzz'}}</h5>
                    <p class="card-text">{{'update-card-description'| translate}}</p>
                    <div>
                        <input type="checkbox" name="faq" [attr.id]="'section' + i.key" class="accordion-input">
                        <label [attr.for]="'section' + i.key" class="accordion-label">{{'update-card-detail'| translate}}</label>
                        <div class="accordion-content">
                            <!-- <p style="font-size: 14px;">
                                <small>TraceToken: {{i.value.traceToken}}</small><br>
                                <small>TokenId: {{i.value.tokenId}}</small>
                            </p> -->
                            <p>{{'update-card-detail-description' | translate}}</p>
                            <div class="container-fluid">
                                <table class="details_table">
                                    <tr>
                                        <td>{{'update-card-detail-1' | translate}}</td>
                                        <td>?</td>
                                    </tr>
                                    <tr>
                                        <td>{{'update-card-detail-2' | translate}}</td>
                                        <td>{{i.value.traceToken}}</td>
                                    </tr>
                                    <tr>
                                        <td>{{'update-card-detail-3' | translate}}</td>
                                        <td>{{i.value.usedAt | json | date:'EEEE, dd, MMMM, HH:mm, zzzz'}}</td>
                                    </tr>
                                    <tr>
                                        <td>{{'update-card-detail-4' | translate}}</td>
                                        <td>?</td>
                                    </tr>
                                    <tr>
                                        <td>{{'update-card-detail-5' | translate}}</td>
                                        <td>?</td>
                                    </tr>
                                    <tr>
                                        <td>{{'update-card-detail-6' | translate}}</td>
                                        <td>?</td>
                                    </tr>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <span class="textpink">{{'update-section-1-title' | translate}}</span>
    <p class="text4">{{'update-section-1-description' | translate}}</p>
    <div class="info2">
        <div class="infotext3">
            <span class="textpink2">{{'extra-section-1-data-1' | translate}}</span>
            <label class="undertext3">{{'extra-section-1-data-1-title' | translate}}</label>
        </div>
        <div class="infotext3">
            <span class="textpink2">{{'extra-section-1-data-2' | translate}}</span>
            <span class="undertext3">{{'extra-section-1-data-2-title' | translate}}</span>
        </div>
        <div class="infotext4">
            <span class="textpink2">{{'extra-section-1-data-3' | translate}}</span>
            <span class="undertext3">{{'extra-section-1-data-3-title' | translate}}</span>
        </div>
    </div>
</div>

<div class="container-fluid">
    <div id="itemContainer" class="row d-flex justify-content-center">
        <div class="col-md itemContainer">
            <a class="row item" target="_blanc" href="https://www.moyeecoffee.com/onze-koffie/">
                <div class="imageContainer">
                    <img class="" src="../../../assets/images/banner_image_coffee@2x.png" />
                </div>

                <div class="col bodyContainer">
                    <div class="title">
                      {{'update-section-2-data-1-title' | translate}}
                    </div>

                    <div class="context">
                      {{'update-section-2-data-1-info' | translate}}
                    </div>
                </div>

                <div class="arrowContainer">
                    <img class="" src="../../../assets/images/arrow_right@2x.png" />
                </div>
            </a>
        </div>

        <div class="col-md itemContainer">
            <a class="row item" target="_blanc" href="https://www.moyeecoffee.com/farmers-impact-dashboard/">
                <div class="imageContainer">
                    <img class="" src="../../../assets/images/banner_image_provenance@2x.png" />
                </div>

                <div class="col bodyContainer">
                    <div class="title">
                      {{'update-section-2-data-2-title' | translate}}
                    </div>

                    <div class="context">
                      {{'update-section-2-data-2-info' | translate}}
                    </div>
                </div>

                <div class="arrowContainer">
                    <img class="" src="../../../assets/images/arrow_right@2x.png" />
                </div>
            </a>
        </div>

        <div class="col-md itemContainer">
            <a class="row item" target="_blanc" href="https://www.moyeecoffee.com/brew-guide/">
                <div class="imageContainer">
                    <img class="" src="../../../assets/images/banner_image_brew_guides@2x.png" />
                </div>

                <div class="col bodyContainer">
                    <div class="title">
                      {{'update-section-2-data-3-title' | translate}}
                    </div>

                    <div class="context">
                      {{'update-section-2-data-3-info' | translate}}
                    </div>
                </div>

                <div class="arrowContainer">
                    <img class="" src="../../../assets/images/arrow_right@2x.png" />
                </div>
            </a>
        </div>
    </div>
</div>
<app-footer></app-footer>
