import { Component, OnInit } from '@angular/core';
import { UserAuthService } from 'src/app/services/user-auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'app-updates',
  templateUrl: './updates.component.html',
  styleUrls: ['./updates.component.css']
})
export class UpdatesComponent implements OnInit {
  public status: string;
  public msg: string;
  public data: any;

  public lang: any;
  public email;
  public numb_planted: number;
  constructor(private route: ActivatedRoute, private user: UserAuthService, public translate: TranslateService) { }

  ngOnInit(): void {
    this.email = this.route.snapshot.params['email_1'];
    this.lang = this.route.snapshot.params['lang'];
    this.translate.use(this.lang);
    this.user.getUserData(this.email).subscribe((res: any) => {


      this.status = res.status;
      console.log(this.data)
      if (this.status === "err") {
        this.msg = res.msg;
        console.log(this.msg)
      } else {
        this.data = res.data;

        let dataArr = Object.keys(res.data);
        return this.numb_planted = dataArr.length;
      }
    })
  }

  log(s) {
    console.log(s);
  }

  kilogram() {
    return this.numb_planted * 25;
  }

  euro() {
    return this.numb_planted * 10;
  }

}
