import { Component, OnInit } from '@angular/core';
import { UserAuthService } from 'src/app/services/user-auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  message: any;

  constructor(public userService: UserAuthService) { }

  ngOnInit(): void {
  }

  signIn(email, password){
    this.userService.SignIn(email, password).then(res => {
      this.message = res

    });
  }
}
