import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { UserAuthService } from '../../services/user-auth.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {


  constructor(
    public translate: TranslateService,
    public userAuth: UserAuthService
  ) { }

  ngOnInit(): void {
  }


  switchLanguage(countryCode){
    this.translate.use(countryCode);
    localStorage.setItem('lang', countryCode)
    this.userAuth.changeMessage(countryCode)
  }
}
